import { format } from 'date-fns';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link, Navigate } from 'react-router-dom';
// import { getAuth, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import GoogleButton from 'react-google-button';
import {auth, provider} from './index.js';
import { signInWithPopup } from '@firebase/auth';
import { useEffect, useState } from 'react';
// import { Redirect } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';




function Copyright(props) {
  const currentYear = format(new Date(), 'yyyy');
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {`Copyright © `}
      <Link color="inherit" href="https://vernalizeme.com/">
      {/* <Link color="inherit" href="https://parvatihospital.com/"> */}
        Vernalizeme
        {/* Smt. Paarvati Devi Hospital  */}
      </Link>{' '}
      {currentYear}
      {`.`}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const [value, setvalue] = useState('');
  useEffect(() => {
    setvalue(localStorage.getItem('displayName'));
    setvalue(localStorage.getItem('email'));
  }, []);


  const handleGoogleSignIn = async () => {
    // const auth = getAuth();
    // const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
    // signInWithRedirect(auth, provider)
      .then((result) => {
        setvalue(result.user.displayName);
        localStorage.setItem('displayName', result.user.displayName);
        setvalue(result.user.email);
        localStorage.setItem('email', result.user.email);

        // setvalue(result.user.photoURL);
        // setvalue(result.user.uid);
        // setvalue(result.user.phoneNumber);
        // setvalue(result.user.providerId);
        // setvalue(result.user.emailVerified);
        // setvalue(result.user.metadata);
        // setvalue(result.user.stsTokenManager);  
        // setvalue(result.user.refreshToken);
        // setvalue(result.user.accessToken);
        // setvalue (result.user.multiFactor);
        // setvalue(result.user.providerData);


        // // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // // The signed-in user info.
        // const user = result.user;
        // // ...
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // The email of the user's account used.
        // const email = error.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // // ...
      });

    }




  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >


            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in Options
            </Typography>

<br />
<div style={{ marginBottom: '50px' }}></div>

            {value ? <Navigate to="/Homepage" /> : 
            <GoogleButton onClick={handleGoogleSignIn} />}
<br />
<div style={{ marginBottom: '50px' }}></div>

          <Button
            href="/loginemail"
            variant="contained"
            size="large"
            sx={{
              height: 48,
              minWidth: 160,
              backgroundColor: 'mediumseagreen',
              color: 'white',
              borderRadius: 0,
              fontWeight: 'normal',
              textTransform: 'none', // To prevent the button text from being in caps
              fontFamily: 'Roboto, sans-serif',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            Sign In with Email
          </Button>




            {/* <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

            <Typography component="h1" variant="h5">
                Sign in with Email
              </Typography>


              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box> */}
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
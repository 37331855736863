import React, { useState, useEffect } from 'react';
// import logo from './logoo.svg';
import './HomePage.css';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom'; // Import for navigation

import logo from './logo.svg';
// const hospitalUrl = "https://parvatihospital.com/";

const hospitalUrl = "https://vernalizeme.com/";
const CopyrightText = styled(Typography)`
  /* CSS styles here */
`;

const Copyright = React.memo((props) => {
    const currentYear = format(new Date(), 'yyyy');
    return (
      <CopyrightText variant="body2" color="text.secondary" align="center" {...props}>
        {`Copyright © `}
        <Link color="inherit" href={hospitalUrl}>
          Vernalizeme
          {/* Smt. Paarvati Devi Hospital  */}
        </Link>{' '}
        {currentYear}
        {'.'}
      </CopyrightText>
    );
  });

const signout = () => {
    localStorage.clear()
    window.location.href = "/"
  }

const Homepage = () => {

    const navigate = useNavigate(); // Access the navigation function
    const [value, setvalue] = useState('');

        useEffect(() => {
            setvalue(localStorage.getItem('displayName'));
        }, []);

  const handleDashboardClick = () => {
    navigate('/Dashboard'); // Use the navigation function
  };

  const handleformClick = () => {
    navigate('/daform'); // Use the navigation function
  };

  return (
    <div className="homepage">
      <header className="header">
        <div className="logo">
          <img src={logo} alt="Logo" style={{ width: "5vw" }} />
        </div>
        <div>
          <button className="sign-out-button" onClick={signout} > Sign Out
          </button>
        </div>
      </header>
      <div className="container">
        <div className="left-side"></div>
        <div className="right-side">

             <div className="welcome-message">
                 Welcome <span>{value || 'Guest'}</span>!
            </div>
            <br />
            <div style={{ marginBottom: '50px' }}></div>
            
          <div className="navigation">
            <button className="navigation-button" onClick={handleformClick}>
              Onboarding Form</button>
            <br />
            <div style={{ marginBottom: '50px' }}></div>

            
            <button className="navigation-button" onClick={handleDashboardClick}>
                Cloud Dashboard
                </button>


          </div>
          <Copyright sx={{ mt: 5 }} />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
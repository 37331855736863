import React, { useState } from 'react';
import { db } from './index.js';
import { auth } from './index.js'; // Import auth from your Firebase configuration file
import { setDoc, doc } from "firebase/firestore";
import './cssdaform.css';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const FORMHeading = () => (
  // <h1>Smt. Paarvati Devi Hospital 
  <h1>Vernalizeme
<br />
<div style={{ marginBottom: '10px' }}></div>
Pre-Boarding Form
</h1>
);

const Copyright = () => {
  const currentYear = format(new Date(), 'yyyy');
  const hospitalUrl = "https://vernalizeme.com/";
  // const hospitalUrl = "https://parvatihospital.com/";

  return (
    <>
      {`Copyright © `}
      <Link to={hospitalUrl} style={{ color: 'lightpink' }}>
        Vernalizeme
        {/* Smt. Paarvati Devi Hospital  */}
      </Link>{' '}
      {currentYear}
      {'.'}
    </>
  );
};


const FORMSubheading = () => (
  <p className="subheading">Please fill out all the sections. 
  <br />
  </p>
);

const FORMSubheading2 = () => (
  <p className="subheading">
  Contact us for any questions
  {/* Contact hr@parvatihospital.com for any questions. */}
  <br/> 
  </p>
);

const MyForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  
  // Add state variables for additional inputs as needed

  const FORMhandleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser; // Get the currently signed-in user

      if (user) { // If a user is signed in
        await setDoc(doc(db, "users", user.uid), {
          name,
          email,
          phoneNumber,
          // Add values from other input fields here
        });

        console.log('Data saved successfully!');
        setName('');
        setEmail('');
        setPhoneNumber('');
        // Clear other input fields here

        // Display success message to the user
        alert('Form submitted successfully!');

        // Redirect the user to the home page
        navigate('/homepage'); // 

      } else {
        console.log('No user is signed in');
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="daform-container">
      <FORMHeading />
      <div style={{ marginBottom: '30px' }}></div>
      <FORMSubheading />
      <div style={{ marginBottom: '70px' }}></div>

      <form onSubmit={FORMhandleSubmit}>
        <label htmlFor="name">Name</label>
        <div style={{ marginBottom: '1px' }}></div>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          required
          className="daform-input"
        />
        <div style={{ marginBottom: '30px' }}></div>

        <label htmlFor="email">Email</label>
        <div style={{ marginBottom: '1px' }}></div>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="daform-input"
        />
        <div style={{ marginBottom: '30px' }}></div>


        <label htmlFor="phoneNumber">Phone Number</label>
        <div style={{ marginBottom: '1px' }}></div>
        <input
          type="tel"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Phone Number"
          required
          className="daform-input"
        />
        
        {/* Add more input fields as needed, following the same structure */}
        <br />
        <div style={{ marginBottom: '40px' }}></div>
        <button type="submit" className='daform-submit' >Submit</button>
        
        </form>

        <div style={{ marginBottom: '70px' }}></div>
        <FORMSubheading2 />
        <Copyright sx={{ mt: 5 }} />

      
    </div>
  );
};

export default MyForm;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
// import { BrowserRouter } from 'react-router-dom';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBXlqzNWonUiJbIgDH6ujduimmQQoRNrkQ",
    authDomain: "reactappreactwebtest.firebaseapp.com",
    projectId: "reactappreactwebtest",
    storageBucket: "reactappreactwebtest.appspot.com",
    messagingSenderId: "80477565020",
    appId: "1:80477565020:web:f89e69eed6309013567699"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <BrowserRouter> */}
      <App />
    {/* </BrowserRouter> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { auth, provider, db};
// import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import Button from '@mui/material/Button';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Welcome! This is a test website for Smt. Paarvati Devi Hospital Cloud Portal.
//         </p>
//         <a
//           className="App-link"
//           href="https://parvatihospital.com/"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Visit SPDH Website
//         </a>

//         <Button variant="contained" color="primary" href="/login">
//         Login
//         </Button>
//       </header>
//     </div>
//   );
// }

// export default App;





import React from 'react';


import './App.css';
import Button from '@mui/material/Button';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Signin from './Signin';
import SignUp from './signup';
import Dashboard from './Dashboard';
import SignEmail from './Signinemail';
import HomePage from './Homepage';
import MyForm from './daform';

import logo from './logo.svg';
// import logo from './logoo.svg';
// App component
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Welcome! This is a test website for our Cloud Portal.
          {/* Welcome! This is a test website for Smt. Paarvati Devi Hospital Cloud Portal. */}
        </p>
        <a
          className="App-link"
          href="https://vernalizeme.com/"
          // href="https://parvatihospital.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit Vernalizeme.com
          {/* Visit SPDH Website */}
        </a>

        <Link to="/login">
        <Button variant="contained" id="login-button" className="login-button">
          Login
         </Button>
        </Link>

      </header>
      {/* Content specific to the root path goes here */}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Signin />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/" element={<App />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/loginemail" element={<SignEmail />} />
        <Route path="/homepage" element={<HomePage />} />
        <Route path="/daform" element={<MyForm />} />
      </Routes>
    </Router>
  );
}

export default AppWrapper;
